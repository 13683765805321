<template>
  <div>
    <div
      class="product-window group-con"
      :class="attr.cartAttr === true ? 'on' : ''"
      :style="'padding-bottom:' + (isShowBtn ? '0' : '')"
    >
      <div class="textpic">
        <div class="tit acea-row row-between">
          <p>三星/Samsung 18650锂电池</p>
        </div>
        <div class="content">
          <p>品牌：三星Samsung</p>
          <p>型号：INR18650-29E</p>
          <p>编号：SXLI29E</p>
          <p>规格：SXLI29E</p>
          <p>库存：SXLI29E</p>
        </div>
        <div class="content acea-row row-left">
          <div class="left">
            <p class="title">销售单价：</p>
          </div>
          <div class="right">
            <p><span>1+：</span><b>￥7.1</b></p>
            <p><span>100+：</span><b>￥6.85</b></p>
            <p><span>500+：</span><b>￥6.80</b></p>
            <p><span>1000+：</span><b>￥6.75</b></p>
          </div>
        </div>
        <!-- <div class="pictrue">
          <img :src="attr.productSelect.image" class="image" />
        </div> -->
        <!-- <div class="text">
          <div class="line1">
            {{ attr.productSelect.store_name }}
          </div>
          <div class="money font-color-red">
            ￥<span class="num">{{ attr.productSelect.price }}</span>
            <span class="stock" v-if="isShow">
              库存: {{ attr.productSelect.stock }}
            </span>
            <span class="stock" v-else>
              限量:
              {{
                attr.productSelect.quota_show ? attr.productSelect.quota_show : 0
              }}
            </span>
          </div>
        </div> -->
        <div class="iconfont icon-guanbi" @click="closeAttr"></div>
      </div>
      <!-- <div class="productWinList">
        <div class="item" v-for="(item, indexw) in attr.productAttr" :key="indexw">
          <div class="title">{{ item.attr_name }}</div>
          <div class="listn acea-row row-middle">
            <div class="itemn" :class="item.index === itemn.attr ? 'on' : ''" v-for="(itemn, indexn) in item.attr_value" @click="tapAttr(indexw, itemn.attr)" :key="indexn">
              {{ itemn.attr }}
            </div>
          </div>
        </div>
      </div> -->
      <div class="cart acea-row row-between">
        <div class="title">购买数量</div>
        <div class="carnum acea-row row-left">
          <div
            class="item reduce"
            :class="proNum <= 1 ? 'on' : ''"
            @click="CartNumDes"
          >
            <!-- <div class="item reduce" :class="attr.productSelect.cart_num <= 1 ? 'on' : ''" @click="CartNumDes" > -->
            -
          </div>
          <div class="item num">
            <input type="number" v-model="proNum" class="ipt_num" />
            <!-- <input type="number" v-model="attr.productSelect.cart_num" class="ipt_num" /> -->
          </div>
          <div
            class="item plus"
            :class="proNum >= storeInfo.stock ? 'on' : ''"
            @click="CartNumAdd"
          >
            <!-- <div class="item plus" :class="attr.productSelect.cart_num >= attr.productSelect.stock ? 'on' : '' " @click="CartNumAdd"> -->
            +
          </div>
        </div>
      </div>
      <div class="detail-btn acea-row row-between">
        <div class="left acea-row row-left">
          <p class="amount">金额：<span>￥21.00</span></p>
        </div>
        <div class="right">
          <p>确定</p>
        </div>
      </div>
      <!-- <div class="wrapper" v-if="isShowBtn">
        <div class="teamBnt bg-color-red" @click="openAlone" v-if=" attr.productSelect.quota > 0 && attr.productSelect.product_stock > 0 ">
          立即参团
        </div>
        <div class="teamBnt bg-color-hui" v-else>已售罄</div>
      </div> -->
    </div>
    <div
      class="mask"
      @touchmove.prevent
      :hidden="attr.cartAttr === false"
      @click="closeAttr"
    ></div>
  </div>
</template>
<script>
export default {
  name: "ProductWindow",
  props: {
    attr: {
      type: Object,
      default: () => {}
    },
    storeInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: function() {
    return {
      proNum: 1
    };
  },
  computed: {
    isShow() {
      return this.$route.path.indexOf("detail") === 1;
    },
    isShowBtn() {
      return this.$route.path.indexOf("group_rule") != -1;
    }
  },
  mounted: function() {
    console.log(this.attr);
  },
  methods: {
    openAlone() {
      this.$emit("changeFun", { action: "goPay", value: false });
    },
    closeAttr: function() {
      this.$emit("changeFun", { action: "changeattr", value: false });
    },
    CartNumDes: function() {
      let that = this;
      that.proNum -= 1;
      if (that.proNum <= 0) {
        that.proNum = 1;
        this.$dialog.toast({ mes: "数量不能小于0" });
      }
      // this.$emit("changeFun", { action: "ChangeCartNum", value: false });
    },
    CartNumAdd: function() {
      let that = this;
      that.proNum += 1;
      if (that.proNum >= that.storeInfo.stock) {
        that.proNum = that.storeInfo.stock;
        this.$dialog.toast({ mes: "数量大于库存" });
      }
      // this.$emit("changeFun", { action: "ChangeCartNum", value: 1 });
    },
    tapAttr: function(indexw, indexn) {
      let that = this;
      that.attr.productAttr[indexw].index = indexn;
      let value = that
        .getCheckedValue()
        .sort()
        .join(",");
      that.$emit("changeFun", { action: "ChangeAttr", value: value });
    },
    //获取被选中属性；
    getCheckedValue: function() {
      let productAttr = this.attr.productAttr;
      let value = [];
      for (let i = 0; i < productAttr.length; i++) {
        for (let j = 0; j < productAttr[i].attr_value.length; j++) {
          if (productAttr[i].index === productAttr[i].attr_value[j].attr) {
            value.push(productAttr[i].attr_value[j].attr);
          }
        }
      }
      return value;
    }
  }
};
</script>
<style scoped>
.joinCart {
  width: 80%;
  height: 0.76rem;
  line-height: 0.76rem;
  text-align: center;
}
.ipt_num {
  width: 100%;
  display: block;
  line-height: 0.54rem;
  text-align: center;
}

.detail-btn {
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  box-shadow: 0px -2px 5px #eaeaea;
  background: #fff;
}
.detail-btn.shop-car {
  bottom: 50px;
}
.detail-btn .left {
  width: 50%;
  height: 100%;
}
.detail-btn .left img {
  width: 21px;
  height: 18px;
}
.detail-btn .left p {
  font-size: 14px;
  color: #8f8f8f;
  line-height: 20px;
}
.detail-btn .left p.amount {
  line-height: 50px;
  margin-left: 10px;
}
.detail-btn .left p.amount span {
  color: #ff7900;
}
.detail-btn .left p.lit {
  font-size: 12px;
  padding: 2px 5px;
}
.detail-btn .left p.lit.tol {
  font-weight: 600;
}
.detail-btn .left p.lit span {
  color: #ff7a00;
}
.detail-btn .left.bat {
  background: #eee;
}
.detail-btn .right {
  width: 50%;
  height: 100%;
  background: #ff7900;
  text-align: center;
}
.detail-btn .right.pay {
  width: 100%;
}
.detail-btn .right.pay-done {
  width: 100%;
  background: #00a10c;
}
.detail-btn .right p {
  font-size: 14px;
  color: #fff;
  line-height: 50px;
}
.detail-btn .right.sub-success {
  width: 100%;
}
.detail-btn .right.bule {
  background: #0294e6;
}
</style>
